export enum EEventKiwidSocket {
    CONNECT = 'connect',
    CONNECT_ERROR = 'connect_error',
    DISCONNECT = 'disconnect',
    ERROR = 'error',
    EXCEPTION = 'exception',
    REFRESH_TOKEN = 'refresh-token',
    // AUTH
    AUTH_UPDATE_TOKEN = 'auth/update-token',
    // CLINIC CHAT
    CLINIC_CHAT_JOIN_ROOM = 'clinic/admin/clinic-chat/join-room',
    CLINIC_CHAT_LEAVE_ROOM = 'clinic/admin/clinic-chat/leave-room',
    CLINIC_CHAT_CREATE_MESSAGE = 'clinic/admin/clinic-chat/create-message',
    CLINIC_CHAT_MESSAGES = 'clinic-chat/messages',
}
