import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IDataPagination } from '../../models/api/data';
import { IChat, IChatBadge, IGetAllChat, IGetAllChatBadge } from '../../models/chat/chat';
import { IError } from '../../models/error/error';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
    providedIn: 'root',
})
export class ChatService implements OnDestroy {
    private readonly router = inject(Router);
    private readonly httpClient = inject(HttpClient);
    private readonly translateService = inject(TranslateService);
    private readonly authenticationService = inject(AuthenticationService);


    ngOnDestroy(): void {
    }

    getAllChat(parameters: IGetAllChat): Observable<IDataPagination<IChat[]>> {
        const baseUrl = ['clinic', 'admin', 'chat'];

        const url = this.router.createUrlTree(baseUrl, {
            queryParams: parameters,
        });

        const parsedUrl = `${environment.apiUrl}/${environment.apiVersion}${url}`;

        return this.httpClient.get(parsedUrl, { responseType: 'json' }).pipe(
            map((res: IDataPagination<IChat[]>) => {
                return res;
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.error instanceof ErrorEvent) {
                    return throwError(() => `Error: ${error.error.message}`);
                }
                const errorApi: IError = error.error.error;
                return throwError(() => errorApi);
            }),
        );
    }

    getAllChatBadges(data: IGetAllChatBadge): Observable<IDataPagination<IChatBadge[]>> {
        const baseUrl = ['clinic', 'admin', 'chat', 'badge'];

        const url = this.router.createUrlTree(baseUrl, {
            queryParams: data,
        });

        const parsedUrl = `${environment.apiUrl}/${environment.apiVersion}${url}`;

        return this.httpClient.get<IDataPagination<IChatBadge[]>>(parsedUrl, { responseType: 'json' }).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.error instanceof ErrorEvent) {
                    return throwError(() => `Error: ${error.error.message}`);
                }
                const errorApi: IError = error.error.error;
                return throwError(() => errorApi);
            }),
        );
    }

    clearChatBadges(): Observable<null> {
        const baseUrl = ['clinic', 'admin', 'chat', 'badge', 'clear'];

        const url = this.router.createUrlTree(baseUrl);

        const parsedUrl = `${environment.apiUrl}/${environment.apiVersion}${url}`;

        return this.httpClient.post<null>(parsedUrl, null, { responseType: 'json' }).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.error instanceof ErrorEvent) {
                    return throwError(() => `Error: ${error.error.message}`);
                }
                const errorApi: IError = error.error.error;
                return throwError(() => errorApi);
            }),
        );
    }
}
